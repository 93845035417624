const FormHeader = () => {
  return (
    <div className="flex flex-col items-start">
      <p className="font-satoshi-bold text-[20px] leading-[30px] text-black">
        Book a Live Demo Session
      </p>
      <p className="font-satoshi-regular text-[16px] leading-6 text-gray-navigation">
        Register to attend the free Live Session
      </p>
    </div>
  );
};

export default FormHeader;
