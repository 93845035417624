import { useState } from 'react';

import DemoClassForm from '@local/courses/forms/demo-class-form/DemoClassForm';
import { Primary, SecondaryColor } from '../../../../../stories/Button.stories';
import useMediaQuery from '@utils/hooks/useMediaQuery';
import { SliderModal } from '@components/local/jobs-board';
import CenterModal from '@components/shared/Modal';
import { IoCloseOutline } from 'react-icons/io5';
import GlobalBrandingHeader from '@components/shared/forms/global-form/GlobalBrandingHeader';

export function DemoClassFormWithCloseBtn({ closeModal, formStyle = '' }) {
  return (
    <div className="relative w-full">
      {closeModal && (
        <div onClick={closeModal} className="absolute right-4 top-4 cursor-pointer">
          <IoCloseOutline size={24} className="text-gray-navigation" />
        </div>
      )}

      <DemoClassForm onBoardingFormStyle={formStyle} />
    </div>
  );
}

function Modal({ showModal, setShowModal }) {
  const isMobile = useMediaQuery('(max-width: 767.98px)');

  if (!showModal) return null;

  const closeModal = () => setShowModal(false);

  return (
    <>
      {isMobile ? (
        <SliderModal isOpen={showModal} onClose={() => setShowModal(false)}>
          <div className="w-full" onClick={(e) => e.stopPropagation()}>
            <DemoClassFormWithCloseBtn closeModal={closeModal} />
          </div>
        </SliderModal>
      ) : (
        <div
          onClick={() => setShowModal(false)}
          className="fixed top-0 left-0 z-[120] h-full w-full bg-opacity-0 backdrop-blur-none"
        >
          <div
            onClick={(e) => {
              e.stopPropagation();
            }}
            className="absolute bottom-4 right-4"
          >
            <div className="">
              <DemoClassFormWithCloseBtn closeModal={closeModal} />
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default function DemoClassFormButton({
  size = '2xl',
  label,
  centerModal = false,
  capitalCase = true,
  outline = false,
}) {
  const [showModal, setShowModal] = useState(false);

  const openModalHandler = () => setShowModal(true);

  return (
    <>
      {centerModal ? (
        <CenterModal
          open={
            <DemoClassButton
              label={label}
              size={size}
              outline={outline}
              capitalCase={capitalCase}
            />
          }
          boxStyle={'md:w-2/6'}
        >
          <GlobalBrandingHeader>
            <DemoClassFormWithCloseBtn formStyle="md:max-h-[51vh] h-fit slim-scroll" />
          </GlobalBrandingHeader>
        </CenterModal>
      ) : (
        <>
          <DemoClassButton
            label={label}
            size={size}
            onClick={openModalHandler}
            outline={outline}
            capitalCase={capitalCase}
          />

          {showModal && <Modal showModal={showModal} setShowModal={setShowModal} />}
        </>
      )}
    </>
  );
}

function DemoClassButton({ label, size, onClick, capitalCase, outline }) {
  return (
    <>
      {outline ? (
        <SecondaryColor
          onClick={() => onClick && onClick()}
          size={size}
          label={label || 'Book Demo Class'}
          capitalCase={capitalCase}
        />
      ) : (
        <Primary
          onClick={() => onClick && onClick()}
          size={size}
          label={label || 'Book Demo Class'}
          capitalCase={capitalCase}
        />
      )}
    </>
  );
}
